import {useEffect, useRef} from 'react';

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// https://www.joshwcomeau.com/snippets/react-hooks/use-timeout/
function useTimeout(callback: any, delay: number) {
    const timeoutRef = useRef(null);
    const savedCallback = useRef(callback);
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
        const tick = () => savedCallback.current();
        timeoutRef.current = window.setTimeout(tick, delay);
        return () => window.clearTimeout(timeoutRef.current);
    }, [delay]);
    return timeoutRef;
}

function isBrowser() {
    return typeof window !== "undefined"
}

function isPosthog() {
    // @ts-ignore
    return isBrowser() && typeof window.posthog !== "undefined"
}

function isGA() {
    // @ts-ignore
    return isBrowser() // && typeof window.google_tag_manager !== "undefined"
}


function searchKeyInDataLayerHistory(key: string) {
    const dataLayerName: string = '_dataLayerHistory';

    if (isBrowser()) {
        // @ts-ignore
        if (typeof window[dataLayerName].history !== "undefined") {
            for (let obj of window[dataLayerName].history) {
                const value = key.split('.').reduce((o, x) => {
                    return (typeof o == 'undefined' || o === null) ? o : o[x];
                }, obj);

                if (value !== undefined && value !== '' && value !== null) {
                    return value;
                }
            }
        }
    }
    return '';
}


function registerEvent(event_name: string, event_props: any) {
    let timeDelay = 5000; // Depends on general script delay for Posthog

    if (isPosthog()) {
        // @ts-ignore
        window.posthog.capture(event_name, event_props);
    } else {
        // console.log(`Posthog not defined - pause ${timeDelay} ms and try again.`)
        useTimeout(() => {
            const currentDate = new Date();
            const pastDateTime = new Date(currentDate.getTime() - timeDelay);

            if (isPosthog()) {
                // @ts-ignore
                window.posthog.capture(event_name, {
                        $timestamp: pastDateTime.toISOString(),
                        ...event_props
                    }
                );
            } else {
                console.log("Posthog still not defined - giving up.")
            }
        }, timeDelay);
    }

    if (isGA()) {
        const ga_event = {'event': event_name};

        // const ga_event = {
        //     ...{'event': event_name},
        //     ...event_props
        // };

        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push(ga_event);
    }
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

//
// function addMissingKeys(obj: any) {
//     const defaultKeys = [
//         "metaTitle",
//         "metaDescription",
//         "metaImage",
//         "metaSocial",
//         "keywords",
//         "metaRobots",
//         "structuredData",
//         "metaViewport",
//         "canonicalURL"
//     ];
//
//     for (const key of defaultKeys) {
//         if (obj[key] === undefined || obj[key] === null || (typeof obj[key] === 'string' && obj[key].trim() === '')) {
//             obj[key] = null;
//         }
//     }
//
//     if (!obj.hasOwnProperty("metaSocial")) {
//         obj.metaSocial = [];
//     }
//
//     const socialNetworks = ["Facebook", "Twitter"];
//     for (const network of socialNetworks) {
//         const socialObj = obj.metaSocial.find(item => item.socialNetwork === network);
//         if (!socialObj) {
//             obj.metaSocial.push({
//                 socialNetwork: network,
//                 title: null,
//                 description: null,
//                 image: null
//             });
//         }
//     }
//
//     return obj;
// }


export {
    isBrowser,
    isPosthog,
    isGA,
    registerEvent,
    classNames,
    useTimeout,
    // addMissingKeys,
    searchKeyInDataLayerHistory
}