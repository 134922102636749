exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artikler-tsx": () => import("./../../../src/pages/artikler.tsx" /* webpackChunkName: "component---src-pages-artikler-tsx" */),
  "component---src-pages-callback-tsx": () => import("./../../../src/pages/callback.tsx" /* webpackChunkName: "component---src-pages-callback-tsx" */),
  "component---src-pages-cookiepolitik-tsx": () => import("./../../../src/pages/cookiepolitik.tsx" /* webpackChunkName: "component---src-pages-cookiepolitik-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jura-forkortelser-tsx": () => import("./../../../src/pages/jura-forkortelser.tsx" /* webpackChunkName: "component---src-pages-jura-forkortelser-tsx" */),
  "component---src-pages-juridisk-ordbog-tsx": () => import("./../../../src/pages/juridisk-ordbog.tsx" /* webpackChunkName: "component---src-pages-juridisk-ordbog-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kunder-tsx": () => import("./../../../src/pages/kunder.tsx" /* webpackChunkName: "component---src-pages-kunder-tsx" */),
  "component---src-pages-licensvilkaar-tsx": () => import("./../../../src/pages/licensvilkaar.tsx" /* webpackChunkName: "component---src-pages-licensvilkaar-tsx" */),
  "component---src-pages-om-tsx": () => import("./../../../src/pages/om.tsx" /* webpackChunkName: "component---src-pages-om-tsx" */),
  "component---src-pages-priser-tsx": () => import("./../../../src/pages/priser.tsx" /* webpackChunkName: "component---src-pages-priser-tsx" */),
  "component---src-pages-privatlivspolitik-tsx": () => import("./../../../src/pages/privatlivspolitik.tsx" /* webpackChunkName: "component---src-pages-privatlivspolitik-tsx" */),
  "component---src-pages-tilmeld-nyhedsbrev-tsx": () => import("./../../../src/pages/tilmeld-nyhedsbrev.tsx" /* webpackChunkName: "component---src-pages-tilmeld-nyhedsbrev-tsx" */),
  "component---src-templates-artikel-tsx": () => import("./../../../src/templates/artikel.tsx" /* webpackChunkName: "component---src-templates-artikel-tsx" */),
  "component---src-templates-categories-tsx": () => import("./../../../src/templates/categories.tsx" /* webpackChunkName: "component---src-templates-categories-tsx" */)
}

