// Remember to restart Gatsby container on change

import './src/styles/global.css'
// import "./src/styles/markdown.css"

import * as React from "react"
// import {Auth0Provider, useAuth0} from '@auth0/auth0-react';
import {navigate} from 'gatsby';

// import RootElement from './src/components/RootElement';

import {isBrowser, isPosthog} from "./src/shared/functions";

// const onRedirectCallback = (appState) => {
//
//     const {user} = useAuth0();
//     console.log("OnRedirectCallback user: " + user)
//     // appState?.returnTo || '/'
//     // Use Gatsby's navigate method to replace the url
//     navigate('/mysterious', {replace: true});
// };


// let routeJustChangedMarker = null;
// https://github.com/gatsbyjs/gatsby/issues/6195
// export function onRouteUpdate({location}) {
//     const isEnabled = process.env.NODE_ENV === 'production';
//     if (!isEnabled) {
//         if (!routeJustChangedMarker) {
//             routeJustChangedMarker = setTimeout(() => {
//                 routeJustChangedMarker = null;
//             }, 500);
//         } else {
//             console.log('Development: Skipping second route update within timeout');
//
//             if (isBrowser()) {
//                 isPosthog() && window.posthog.capture('$pageview');
//             }
//
//             return;
//         }
//     }
// }

// In development mode onRouteUpdate is called twice - not in production
export const onRouteUpdate = () => {
    // console.log('$pageview', arguments)
    if (isBrowser()) {
        // @ts-ignore
        isPosthog() && window.posthog.capture('$pageview');
    }
}

// export const wrapRootElement = ({ element }) => {
//   return <RootElement>{element}</RootElement>;
// };


// export const wrapRootElement = ({element}) => {
//     return (
//         <>
//             <Auth0Provider
//                 domain={process.env.GATSBY_AUTH0_DOMAIN}
//                 clientId={process.env.GATSBY_AUTH0_CLIENTID}
//                 redirectUri={process.env.GATSBY_AUTH0_CALLBACK}
//                 onRedirectCallback={onRedirectCallback}
//                 // onRedirecting={onRedirecting}
//                 // audience="https://api.example.com/"
//                 // useRefreshTokens
//                 // cacheLocation="localstorage"
//             >
//                 {element}
//             </Auth0Provider>
//         </>
//     );
// };


//redirectUri={gatsbyConfig.siteMetadata.siteUrl} // {process.env.AUTH0_REDIRECTURI}
//redirectUri={window.location.origin} //  + "/love"